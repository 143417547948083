import { createI18n } from 'vue-i18n';

const i18n = createI18n({
  locale: 'en', // Lingua predefinita
  fallbackLocale: 'en',
  messages: {
    en: {
      // Traduzioni in inglese
    },
    // Aggiungi altre lingue e le loro traduzioni qui
  },
});

export default i18n;
