import { createRouter, createWebHistory } from "vue-router";
import axios from "axios";
import localforage from "localforage";

const configAuth = (process.env.VUE_APP_AUTHENTICATION==='0' ? false : true);

const routes = [
    { path: "/", redirect: (configAuth ? "/login" : "/home") },
    {
        path: "/login",
        component: () => import("@/views/LoginView.vue"),
        meta: { requiresAuth: false },
    },
    {
        path: "/home",
        component: () => import("@/views/HomeView.vue"),
        meta: { requiresAuth: configAuth },
    },
    {
        path: "/run",
        component: () => import("@/views/RunView.vue"),
        meta: { requiresAuth: configAuth },
    },
    {
        path: "/settings",
        component: () => import("@/views/SettingsView.vue"),
        meta: { requiresAuth: configAuth },
    },
    {
        path: "/blog",
        component: () => import("@/views/BlogView.vue"),
        meta: { requiresAuth: configAuth },
    },
    {
        path: "/workout",
        component: () => import("@/views/WorkoutView.vue"),
        meta: { requiresAuth: configAuth },
    },
    {
        path: "/nutrition",
        component: () => import("@/views/NutritionView.vue"),
        meta: { requiresAuth: configAuth },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (to.meta.requiresAuth) {
        const token = await localforage.getItem("token");
        const username = await localforage.getItem("username");
        const password = await localforage.getItem("password");

        axios.defaults.baseURL = process.env.VUE_APP_API_URL;

        await localforage.removeItem('token');
        try {
            //const response = await axios.post("http://localhost:3001/validate", { token, username, password });
            //const response = await axios.post("https://andnowrun.yourpersonaltrainer.ai:3001/validate", { token, username, password });
            const response = await axios.post("/validate", { token, username, password });

            if('token' in response.data && response.data.token !== ''){
                await localforage.setItem('token', response.data.token);
                next();

            } else {
                next("/login");
                throw new Error('Session Expired');
                
            }

        } catch (error) {
            next("/login");
            throw new Error('Invalid Username/Password');
        }

    } else {
        next();
    }
});

export default router;
